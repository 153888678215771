import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import PropTypes from 'prop-types'
import styles from './community_card.module.css'
import { useAnchorClickTracking } from '@envato/google-analytics'

const CommunityCard = ({ title, stepNumber, imageSrc, text, linkText, href }) => {
  const trackClick = useAnchorClickTracking('step_cta', `step_${stepNumber}`)

  return (
    <div className={styles.root}>
      {stepNumber && <div className={styles.number}>{stepNumber}</div>}
      <div className={styles.image}>
        <img loading='lazy' src={imageSrc} alt={title} />
      </div>
      <div className={styles.content}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.text}>{text}</p>
      </div>
      {href && linkText && (
        <AnchorWithArrow className={styles.anchor} href={href} onClick={trackClick}>
          {linkText}
        </AnchorWithArrow>
      )}
    </div>
  )
}

CommunityCard.propTypes = {
  title: PropTypes.string.isRequired,
  stepNumber: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  linkText: PropTypes.string,
  href: PropTypes.string
}

export default CommunityCard
