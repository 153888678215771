import React from 'react'
import { AnchorWithArrow } from '@envato/ui'
import styles from './title.module.css'
import PropTypes from 'prop-types'
import Text from '../text/text'

const Title = ({
  royaltyText,
  titleText,
  supportingText,
  linkHref,
  linkLabel,
  isMainPageTitle = false,
  alignment = 'center'
}) => {
  return (
    <div className={alignment === 'left' ? styles.rootLeft : styles.rootCenter}>
      <Text type='preheading' block>
        {royaltyText}
      </Text>
      <Text type={isMainPageTitle ? 'mega' : 'heading'} block>
        {titleText}
      </Text>
      {supportingText ? (
        <Text type='supporting' block>
          {supportingText}
        </Text>
      ) : null}
      {linkHref ? (
        <AnchorWithArrow className={styles.link} href={linkHref}>
          {linkLabel}
        </AnchorWithArrow>
      ) : null}
    </div>
  )
}

Title.propTypes = {
  royaltyText: PropTypes.node.isRequired,
  titleText: PropTypes.node.isRequired,
  supportingText: PropTypes.node,
  linkHref: PropTypes.string,
  linkLabel: PropTypes.string,
  isMainPageTitle: PropTypes.bool,
  alignment: PropTypes.oneOf(['center', 'left'])
}

export default Title
