import React from 'react'
import Title from '../title/title'
import CommunityCard from '../community_card/community_card'
import CenteredSectionWrapper from '../centered_section_wrapper/centered_section_wrapper'
import img1 from './step1.svg'
import img2 from './step2.svg'
import img3 from './step3.svg'
import styles from './community_section.module.css'

const content = [
  {
    stepNumber: '1',
    imageSrc: img1,
    title: 'Authors upload their work',
    text: 'Our authors care deeply about the quality of the assets they sell.',
    linkText: 'Become an Author',
    href: 'https://author.envato.com/'
  },
  {
    stepNumber: '2',
    imageSrc: img2,
    title: 'Customers download assets',
    text: 'From freelancers and creative agencies to some of the biggest brands on the planet.',
    linkText: 'Browse Products',
    href: 'https://www.envato.com/products'
  },
  {
    stepNumber: '3',
    imageSrc: img3,
    title: 'Authors earn money from their sales',
    text: 'Envato authors have earned more than $1 billion USD in total earnings.',
    linkText: 'See Our Profit Distribution',
    href: 'https://docs.google.com/presentation/d/e/2PACX-1vSPUj2ruDjLqF8yrz1gOMouFHtvWxyAe2nO00pZ7sbaW5wNDs1Cxfhr-_bJvKm8xOhjFxGWsPnsJS7q/pub?start=false&loop=false&delayms=3000'
  }
]

const CommunitySection = () => (
  <div className={styles.root}>
    <CenteredSectionWrapper>
      <div className={styles.text}>
        <Title
          royaltyText='Our Community'
          titleText='A global creative community'
          supportingText='We connect customers to world-class assets by Envato authors around the globe.'
          alignment='left'
        />
        <div className={styles.steps}>
          {content.map((card, i) => (
            <CommunityCard
              title={card.title}
              stepNumber={card.stepNumber}
              imageSrc={card.imageSrc}
              text={card.text}
              linkText={card.linkText}
              href={card.href}
              key={i}
            />
          ))}
        </div>
      </div>
    </CenteredSectionWrapper>
  </div>
)

export default CommunitySection
